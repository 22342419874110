const lemonConstants = {
  downloadLemonAppLink: 'https://lmn.page/app',
  social: {
    instagram: 'https://www.instagram.com/energialemon',
    linkeding: 'https://www.linkedin.com/company/lemon-energia/',
    youtube: 'https://www.youtube.com/@energialemon',
  },
  links: {
    conductCode: 'https://lmn.page/codigo-etica-conduta',
    complianceReport: 'https://lmn.page/compliance_report',
    careers: 'https://lmn.page/vagas',
  },
  videos: {
    howLemonWorks: {
      title: 'Entenda como funciona Lemon num minuto',
      href: 'https://www.youtube.com/watch?v=TH7Q-EB5-xQ',
    },
  },
} as const

export default lemonConstants
